import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const DynamicGallery = ({ newpagecontent }) => {
  // State for controlling the modal
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // Function to open the modal with the clicked image
  const handleOpen = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpen(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  // Function to initialize gallery items (useEffect for dynamic content)
  const initializeGallery = () => {
    const imageElements = document.querySelectorAll('.gallery-image');
    imageElements.forEach((element) => {
      element.addEventListener('click', () => handleOpen(element.src));
    });
  };

  // Re-initialize gallery when new page content is loaded
  useEffect(() => {
    initializeGallery();
  }, [newpagecontent]); // Re-initialize when page content changes

  return (
    <>
      {/* Render the dynamic content, including the gallery */}
      <div
        className="dynamic-gallery-container"
        dangerouslySetInnerHTML={{ __html: newpagecontent }}
      />

      {/* Modal to display full-screen image */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img
            src={currentImage}
            alt="Selected Image"
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </DialogContent>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  );
};

export default DynamicGallery;
