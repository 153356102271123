import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faYoutube,
  } from '@fortawesome/free-brands-svg-icons';
const Container = styled(Box)({
  justifyContent: 'center',
  gap: '16px', // space between cards
  padding: '16px',
});

const StyledCard = styled(Card)({
  maxWidth: '242px',
  flex: '1 1 calc(25% - 16px)', // four cards per row max
  marginBottom: '16px',
});

export default function AuthorCard({ memberslist }) {
  const memberslist_json = JSON.parse(memberslist);
  const onclickAuthorURL = (pageurl) => {
    window.open(pageurl, '_target');
  }
  return (
    <Container sx={{display: { lg: "flex", xs: "row" }, flexWrap: { lg: "wrap", xs: "row" }}}>
      {memberslist_json!== null && memberslist_json.map((member, index) => (
        <StyledCard key={index} sx={{maxWidth: { lg: "242px", xs: "100%" }}}>
          <CardMedia
            component="img"
            height="auto"
            width="100%"
            image={member.img}
            alt={member.full_name}
          />
          <CardContent sx={{mb: 0, pb: 0}}>
            <Typography gutterBottom variant="h5" component="div" sx={{fontSize: '1rem'}}>
              {member.full_name}
            </Typography>
            <Typography gutterBottom variant="h5" component="div" sx={{fontSize: '13px', color: '#008d8e'}}>
              {member.aff}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
                <IconButton aria-label="Lined In" sx={{fontSize: '17px'}} onClick={() => onclickAuthorURL(member.linkedin)}>
                <   FontAwesomeIcon  icon={faLinkedinIn } sx={{fontSize: '17px'}} />
                </IconButton>
          </CardActions>
        </StyledCard>
      ))}
    </Container>
  );
}
