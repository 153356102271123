import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Link,
  Avatar,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import { AspectRatio } from '@mui/joy';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pageUserName, setPageUserName] = useState('');
  const [pagemetatags, setPageMetaTags] = useState([]);

  const fetchPageData = async () => {
    try {
      const userData = localStorage.getItem('user');
      let session_bdo_id = '';

      if (userData) {
        const { expiresAt, customlog } = JSON.parse(userData);
        const currentTime = new Date().getTime();
        const expirationTime = new Date(expiresAt).getTime();
        if (currentTime < expirationTime) {
          session_bdo_id = customlog;
        }
      }
      const response = await axios.get(`https://broadwayoriginals.com/apis/index.php?dashboard=${session_bdo_id}`);
      setLoaderState(false);
      document.title = "Broadway Originals | Dashboard";
      const responseData = response?.data || {};
      setData(response.data.pagedata);
      setNavlist(
        <Header
          navlist={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
      setPageFooter(
        <Footer
          nav_list={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setPageMetaTags(responseData.pagemetatags || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { username, expiresAt } = JSON.parse(userData);
      if (expiresAt && new Date(expiresAt) > new Date()) {
        setPageUserName(username);
        fetchPageData();
      } else {
        window.location = "/signin";
      }
    } else {
      window.location = "/signin";
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100%',
          color: '#fff',
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, mt: 5, mb: 5, color: 'black', minHeight: '50vh' }}>          
            <Box sx={{ padding: 2 }}>
              <Typography variant="h4" component="h1" sx={{ color: '#008989' }} gutterBottom>
                Welcome, {pageUserName}!
              </Typography>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  WebkitLineClamp: 2, 
                                  textOverflow: 'ellipsis',
                                  maxHeight: '3em',
                                  lineHeight: '1.5em',
                                }}
                              >
                                Saved Posts
                  </Typography>
                </Grid>
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Grid item xs={12} sm={12} md={12} key={item.post_id}>
                        <Card
                          variant="outlined"
                          orientation="horizontal"
                          sx={{
                            border: 'none',
                            display: 'flex',
                            background: 'none',
                            width: '100%',
                            '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
                            padding: '0px',
                          }}
                        >
                          <AspectRatio ratio="1" sx={{ minWidth: 120 }}>
                            <img
                              src={`https://broadwayoriginals.com/${item.post_img}`}
                              loading="lazy"
                              alt={item.post_title}
                              style={{ borderRadius: '6px', width: '120px', height: '120px', objectFit: 'cover' }} // Set fixed width and height
                            />
                          </AspectRatio>
                          <CardContent sx={{ pt: 0, mt: 0, flex: '1 1 auto' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
                              <Avatar
                                src={`https://broadwayoriginals.com/${item.author.img}`}
                                alt={item.author.name}
                                sx={{ width: 24, height: 24, mr: 1 }}
                              />
                              <Typography variant="body2" color="text.secondary">
                                {item.author.name}
                              </Typography>
                            </Box>
                            <Link href={`/${item.custom_url}`} underline="hover" color="inherit" sx={{ flexGrow: 1 }}>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  WebkitLineClamp: 2, 
                                  textOverflow: 'ellipsis',
                                  maxHeight: '3em',
                                  lineHeight: '1.5em',
                                }}
                              >
                                {item.post_title}
                              </Typography>
                            </Link>
                          </CardContent>
                        </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" align="center" color="text.secondary" sx={{ width: '100%' }}>
                    No posts available.
                  </Typography>
                )}
              </Grid>
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default Dashboard;
