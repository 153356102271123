import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Material-UI icons for arrows

const Banners = ({ bannerslist }) => {
  const banners = JSON.parse(bannerslist); // Parse JSON data

   // Custom Next Arrow
   const NextArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px', // Adjust right position to ensure it's visible
          transform: 'translateY(-50%)',
          zIndex: 2, // Ensure it's above the slider
          backgroundColor: '#ffffff', // Background color for better visibility
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    );
  };

  // Custom Previous Arrow
  const PrevArrow = ({ onClick }) => {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px', // Adjust left position to ensure it's visible
          transform: 'translateY(-50%)',
          zIndex: 2, // Ensure it's above the slider
          backgroundColor: '#ffffff', // Background color for better visibility
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <ArrowBackIos />
      </IconButton>
    );
  };

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false, 
    nextArrow: <NextArrow />, 
    prevArrow: <PrevArrow />, 
  };
 
  return (
    <Box sx={{ width: '100%', overflow: 'hidden', boxSizing: 'border-box' }}>
      <div style={{ width: '100%', maxWidth: '100%' }}>
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <Box key={index} sx={{ position: 'relative', width: '100%' }}>
              <a href={banner.banner_link} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
                <img
                  src={`https://broadwayoriginals.com/${banner.image}`}
                  alt={banner.name}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '600px',
                    display: 'block',
                    objectFit: 'cover',
                  }}
                />
              </a>
              {/* Banner name overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '5px 10px',
                  borderRadius: '5px',
                }}
              >
                <Typography variant="subtitle1" sx={{ color: 'white', textAlign: 'center' }}>
                  {index + 1}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </div>
    </Box>
  );
};

export default Banners;
