import React from 'react';
import Section from './HomePost';
import money_img from '../images/money.png';
function Money({ todaynews }) {
  const todaynews_list = JSON.parse(todaynews);

  return (
    <>
      {todaynews_list.length > 0 && (
        <Section
          title="Money"
          image={money_img}
          newsData={todaynews_list}
          sectionUrl="/money"
        />
      )}
    </>
  );
}

export default Money;
