import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Menu, Button, MenuItem, Container, IconButton, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group'; // For animations
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import '../css/styles.css'; // Custom styles

const MainNav = ({ navlist }) => {
  const navData = JSON.parse(navlist);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subDropdownAnchorEl, setSubDropdownAnchorEl] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeCity, setActiveCity] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchOpen, setSearchOpen] = React.useState(false);
  // Media query to detect if screen is mobile
  const isMobile = useMediaQuery('(max-width:600px)');
 
  const handleToggleDropdown = (event, category) => {
    setAnchorEl(anchorEl === event.currentTarget ? null : event.currentTarget);
    setActiveCategory(category);
    setSubDropdownAnchorEl(null); // Close city submenus when switching categories
    setActiveCity(null);
  };
  const onclickOpenMenuURL = (pageurl) => {
    window.location = "/"+pageurl;
  }
  const handleToggleSubDropdown = (event, city) => {
    setSubDropdownAnchorEl(subDropdownAnchorEl === event.currentTarget ? null : event.currentTarget);
    setActiveCity(city);
  };
  const handleResultClick = (result) => {
    window.location = '/' + result.custom_url;
    setSearchOpen(false);
  };
  const renderNestedItems = (subCategories, cityurl, category_url) =>
    subCategories.map((sub) => (
      <MenuItem 
        key={sub.ref} 
        component={Link} 
        to={cityurl ? `/${category_url}/${cityurl}/${sub.custom_url}` : `/${category_url}/${sub.custom_url}`}
      >
        {sub.name}
      </MenuItem>
    ));
    const handleSearchChange = async (event) => {
      const query = event.target.value;
      setSearchQuery(query);
  
      if (query.length > 2) {
        setLoading(true);
        try {
          const response = await axios.post('https://broadwayoriginals.com/apis/index.php', { search: query });
          setSearchResults(response.data);
          setSearchOpen(true);
        } catch (error) {
          console.error('Error fetching search results:', error);
          setSearchResults([]);
          setSearchOpen(false);
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults([]);
        setSearchOpen(false);
      }
  };
  const renderMultiLevelDropdown = (category) => {
    if (!category.data || !category.data.cities) return null;

    return (
      <CSSTransition in={Boolean(anchorEl) && activeCategory === category.category_name} timeout={300} classNames="dropdown">
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && activeCategory === category.category_name}
          onClose={() => setAnchorEl(null)}
        >
          {category.data.cities.map((city) => (
            <Box key={city.id}>
              <MenuItem onClick={(event) => handleToggleSubDropdown(event, city.name)}>
                {city.name}
                {activeCity === city.name ? <ArrowDropUp /> : <ArrowDropDown />}
              </MenuItem>

              {/* Render subcategories dropdown to the right */}
              <CSSTransition in={Boolean(subDropdownAnchorEl) && activeCity === city.name} timeout={300} classNames="dropdown">
                <Menu
                  anchorEl={subDropdownAnchorEl}
                  open={Boolean(subDropdownAnchorEl) && activeCity === city.name}
                  onClose={() => setSubDropdownAnchorEl(null)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  {renderNestedItems(category.data.sub_categories, city.urlstring, category.custom_url)}
                </Menu>
              </CSSTransition>
            </Box>
          ))}
        </Menu>
      </CSSTransition>
    );
  };

  const renderDropdownMenu = (category) => {
    const hasSubCategories = category.data && category.data.sub_categories && category.data.sub_categories.length > 0;
    if (hasSubCategories) {
      return (
        <CSSTransition in={Boolean(anchorEl) && activeCategory === category.category_name} timeout={300} classNames="dropdown">
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeCategory === category.category_name}
            onClose={() => setAnchorEl(null)}
          >
            {hasSubCategories ? renderNestedItems(category.data.sub_categories, "", category.custom_url) : ""}
          </Menu>
        </CSSTransition>
      );
    }
  };

  return (
    <AppBar position="static" sx={{ background: 'transparent' }}>
      <Container maxWidth="lg" sx={{padding: "0px !important"}}>
        
        <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignItems: 'center', width: "100% !important" }}>
           {/* Search Bar */}
           <Box sx={{ position: 'relative', width: '100%' }}>
              {isMobile && (
                <TextField
                variant="outlined"
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{
                  width: '100%',
                  margin: 0, // Remove any additional margin
                  padding: 1, // Remove padding if causing overflow
                  borderColor: 'rgb(0 155 155)',
                  color: 'black',
                }}
                fullWidth
              />
              )}
              
              {/* Render search results directly below the TextField */}
              {searchOpen && (
                <Box
                  className="search-scroll" // Add class for custom scrollbar styling
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    boxShadow: 1,
                    zIndex: 1,
                    maxHeight: '400px', // Optional: Add max height for better UX
                    overflowY: 'auto', // Allow vertical scrolling
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                  }}
                >
                  {loading ? (
                    <MenuItem sx={{ color: "black" }}>Loading...</MenuItem>
                  ) : (
                    searchResults.map((result) => (
                      <MenuItem
                        key={result.custom_url}
                        onClick={() => handleResultClick(result)}
                        sx={{ color: "black", display: 'flex', alignItems: 'center', padding: '10px' }}
                      >
                        {result.post_image && (
                          <Box
                            component="img"
                            src={"https://broadwayoriginals.com/" + result.post_image}
                            alt={result.post_title}
                            sx={{
                              width: '60px', // Image width
                              height: '60px', // Image height
                              borderRadius: '4px',
                              marginRight: '10px',
                            }}
                          />
                        )}
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              overflowWrap: 'break-word', // Allows long words to break
                              wordBreak: 'break-word', // Breaks words if necessary
                              whiteSpace: 'normal', // Allows the text to wrap
                            }}
                          >
                            {result.post_title}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'gray' }}>
                            By {result.author_name} | {result.category.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </Box>
              )}
            </Box>
          {/* Render the Menu for Desktop Only */}
          {!isMobile && (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              {navData.map((category) => {
                const hasSubcategories = category.data && category.data.sub_categories;

                return (
                  <Button
                    key={category.category_name}
                    onClick={(event) => handleToggleDropdown(event, category.category_name)}
                    sx={{
                      my: 2,
                      mx: 1, // Margin between buttons
                      color: activeCategory === category.category_name ? '#2a4b4b' : 'black', // Active state styling
                      border: activeCategory === category.category_name ? '2px solid #2a4b4b' : 'none', // Active underline
                      whiteSpace: 'nowrap', // Prevent text from breaking into multiple lines
                      width: 'auto',
                      
                    }}
                    component={hasSubcategories ? 'button' : Link}
                    to={!hasSubcategories ? `/${category.custom_url}` : undefined}
                  >
                    <Typography sx={{ marginRight: '8px', fontWeight: activeCategory === category.category_name ? 'bolder' : '100',  }}>
                      {category.category_name}
                    </Typography>
                    {hasSubcategories && (activeCategory === category.category_name ? <ArrowDropUp /> : <ArrowDropDown />)}
                  </Button>
                );
              })}

              {/* Render multi-level dropdown for 'City Guides' */}
              {navData.map((category) => category.category_name === 'City Guides' && renderMultiLevelDropdown(category))}
              {/* Render dropdowns for other categories */}
              {navData.map((category) => category.category_name !== 'City Guides' && renderDropdownMenu(category))}
            </Box>
          )}

          
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainNav;
