import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Badge,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlayCircle } from "@fortawesome/free-solid-svg-icons"; 
import { NavLink } from 'react-router-dom';
import '../css/styles.css'; // Import your styles

function Video({ videoslist }) {
  const videos_list = JSON.parse(videoslist);
  const [showVideo, setShowVideo] = useState(false);
  
  // To detect if we are on a mobile device
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = () => {
    setShowVideo(true);
  };

  const onclickOpenPages = () => {
    window.location = "/videos";
  };

  return (
    <>
      {videos_list && videos_list.length > 0 && (
        <Grid container sx={{ mb: 6, background: "black" }}>
          <Container maxWidth="lg" sx={{padding: { lg: "0px !important", xs: "16px" }}}>
            {/* Title and Explore Button */}
            <Grid container spacing={2} sx={{ mb: 4, alignItems: 'center', mt: 3 }}>
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h4"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#00a8a9',
                    fontSize: { xs: '24px', md: '32px' },
                  }}
                >
                  <FontAwesomeIcon icon={faPlayCircle} style={{ marginRight: '8px' }} />
                  Videos
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
                <NavLink to="/videos" style={{ textDecoration: 'none' }}>
                  <Badge
                    sx={{
                      fontSize: "18px",
                      background: "white",
                      color: "#00a8a9",
                      borderRadius: "20px",
                      padding: "10px",
                      cursor: "pointer",
                      border: "1px solid #00a8a9"
                    }}
                  >
                    <span style={{ fontWeight: 200 }}>
                      Explore More <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </Badge>
                </NavLink>
              </Grid>
            </Grid>

            {/* Video Cards Layout */}
            <Grid container spacing={2}>
              {/* Main Video */}
              <Grid item xs={12} md={7}>
                {videos_list.map((item, index) => index === 0 && (
                  <Card key={index} sx={{ border: "none", background: "none", borderRadius: "20px", mb: 2 }}>
                    {showVideo ? (
                      <iframe
                        width="100%"
                        height="350"
                        src={item.video_link}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ borderRadius: "20px", cursor: 'pointer' }}
                      ></iframe>
                    ) : (
                      <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item.post_img}`}
                        alt="Video Cover"
                        loading="lazy"
                        sx={{ borderRadius: "20px", cursor: 'pointer' }}
                        onClick={handleClick}
                      />
                    )}
                    <CardContent sx={{ padding: 1 }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Avatar src={`https://broadwayoriginals.com/${item.author.img}`} alt={item.author.name} sx={{ width: 30, height: 30, mr: 1 }} />
                        <span style={{ color: "white" }}>{item.author.name}</span>
                      </Typography>
                      <Typography 
                        variant="h6" 
                        component={NavLink} 
                        to={item.custom_url} 
                        sx={{ 
                          textDecoration: 'none', 
                          color: 'white', 
                          fontWeight: "bold",
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 2
                        }}
                      >
                        {item.post_title}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Grid>

              {/* Other Videos */}
              <Grid item xs={12} md={5}>
                {videos_list.map((item1, index1) => index1 !== 0 && (
                  <Grid container spacing={1} key={index1} sx={{ mb: 2 }}>
                    <Grid item xs={5}>
                      <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item1.post_img}`}
                        alt={item1.post_title}
                        loading="lazy"
                        sx={{ width: '100%', borderRadius: '20px' }}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <NavLink to={item1.custom_url} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: 'white', 
                            mb: 1, 
                            fontWeight: "bold",
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2
                          }}
                        >
                          {item1.post_title}
                        </Typography>
                      </NavLink>
                      <Typography variant="body2" sx={{ color: 'gray' }}>
                        Uploaded by {item1.author.name} | Views: {item1.post_views || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Mobile View "Explore More" Button */}
            {isMobile && (
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <Badge
                    sx={{
                      fontSize: "18px",
                      background: "none",
                      color: "#00a8a9",
                      borderRadius: "20px",
                      padding: "10px",
                      cursor: "pointer",
                      border: "1px solid #00a8a9",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={onclickOpenPages}
                  >
                    <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Badge>
                </Grid>
              </Grid>
            )}
          </Container>
        </Grid>
      )}
    </>
  );
}

export default Video;
