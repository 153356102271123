// src/components/Editor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box } from '@mui/material';

const Editor = ({ setContent }) => {
  const handleChange = (value) => {
    setContent(value);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
      <ReactQuill onChange={handleChange} style={{ minHeight: '300px' }} />
    </Box>
  );
};

export default Editor;
