import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button, Avatar, useMediaQuery, useTheme, Link, Divider
} from '@mui/material';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './ForgotPassword';
import SvgIcon from '@mui/joy/SvgIcon';
import MapIcon from '@mui/icons-material/Map';
import LaptopIcon from '@mui/icons-material/Laptop';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LoginComponent from '../components/LoginComponent';
const SignIn = () => {
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    username: '',
    pwd: '',
    user_login: ''
 });
const handleClickOpen = () => {
  setOpen(true);
};
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};
const handleClose = () => {
  setOpen(false);
};
const handleSubmitT = async (e) => {
  e.preventDefault();
  try {
    if (formData.username !== "" && formData.pwd !== "") {
      const response = await fetch('https://broadwayoriginals.com/apis/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.username,
          pwd: formData.pwd,
          user_login: ''
        }),
      });
      const resdata = await response.json();
      if (response.ok) {
        const headers = response.headers;
        const customlog = headers.get('Login'); 
        const expiresAt = new Date(Date.now() + 3600000).toISOString();
        localStorage.setItem('user', JSON.stringify({ ...resdata, expiresAt, customlog }));
        window.location.href = "/dashboard";
      }else{
        toast.error(resdata.message);
      }
    }
  } catch (error) {
    console.log(error);
    toast.error("Error submitting form");
  }
};

const checkSession = () => {
  const loginKey = localStorage.getItem("loginKey");
  const expiration = localStorage.getItem("expiration");

  if (loginKey && expiration) {
    const currentTime = new Date().getTime();
    const expirationTime = new Date(expiration).getTime();

    if (currentTime < expirationTime) {
      window.location.href = "/dashboard";
    }
  }
};

  const fetchPageData = async () => {
    try {
      setLoaderState(true);
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
      document.title = "Broadway Originals | Contact Us";
      const responseData = response?.data || {};

      setNavlist(
        <Header
          navlist={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
      setPageFooter(
        <Footer
          nav_list={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setPageMetaTags(responseData.pagemetatags || []);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderState(false);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { expiresAt } = JSON.parse(userData);
      if (expiresAt && new Date(expiresAt) > new Date()) {
        window.location = "/dashboard";
      }else{
        fetchPageData();
      }
    }else{
      fetchPageData();
    }
    
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100%',
          color: '#fff',
          
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '10px', md: '0px' }, mt: 3, mb: 5, color: 'black' }}>          
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card
                    size="lg"
                    variant="plain"
                    orientation={isMobile ? "vertical" : "horizontal"}
                    sx={{
                      textAlign: 'left',
                      maxWidth: '100%',
                      // to make the demo resizable
                      resize: 'horizontal',
                      border: '1px solid #d9e5e5'  //#0bafb1
                    }}
                  >  
                    <CardContent sx={{ gap: 1.5, minWidth: 100 }}>
                      <CardContent>
                      <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                          color: 'rgb(0, 123, 124)',
                          fontSize: '1.5rem',
                          fontWeight: 'bold',
                          display: 'inline-block',
                          transition: 'transform 0.3s ease',
                          transformStyle: 'preserve-3d',
                          textAlign: 'left',
                          '&:hover': {
                            transform: 'rotateY(10deg) rotateX(10deg)',
                          },
                        }}
                      >
                        Sign In
                      </Typography>
                      <Typography sx={{fontSize: '15px', textAlign: 'left'}}>Don't have an account?  <a href="/signup" style={{ textDecoration: 'none', color: '#009697' }}>Sign up</a>
                                  </Typography>
                      <form onSubmit={handleSubmitT}>
                                
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email Address"
                                    name="username"
                                    type="email"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                               <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Password"
                                    name="pwd"
                                    type="password"
                                    value={formData.pwd}
                                    onChange={handleChange}
                                />
                                  <Link
                                    component="button"
                                    type="button"
                                    onClick={handleClickOpen}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline', textDecoration: 'none', float: 'right', color: '#009697' }}
                                  >
                                    Forgot your password?
                                  </Link>
                                  <ForgotPassword open={open} handleClose={handleClose} />
                                <Box >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="submit-btn"
                                        style={{ background: "#00A8AA", color: "white", marginTop: "16px", width: '100%' }}
                                    >
                                        Sign In
                                    </Button>
                                  
                                  <Divider sx={{mt: 3, mb: 3}}>or</Divider>
                                  <LoginComponent></LoginComponent>
                                   
                                </Box>
                            </form>
                      </CardContent>
                      
                    </CardContent>
                    <CardOverflow
                      variant="solid"
                      color="primary"
                      sx={{
                        flex: '0 0 65%',
                        display: 'flex',
                        flexDirection: 'column',
                        px: 'var(--Card-padding)',
                        background: '#0086861c',
                        p: 0
                      }}
                    >
                      
                      <Box display="flex" mb={3} sx={{p: 0}}>
                        <Box ml={2}>
                          <Card variant="plain" sx={{background: 'none', pb: 1}}>
                              <CardContent>
                                <Box display="flex" alignItems="center">
                                 <Avatar sx={{mr: 2, bgcolor:'#fbfcfe', border: '1px solid #00a8aa', width: '46px', height: '46px' }} ><MapIcon sx={{ color: '#008e8f', fontSize: '27px'}} /></Avatar>
                                  
                                  <Typography level="title-md" sx={{ color: '#444', fontWeight: 'bold', fontSize: '17px' }}>
                                    Curated City Guides
                                    <Typography sx={{ fontSize: '15px', color: '#444'}}>
                                    Discover top spots and hidden gems with expert recommendations for every interest.
                                  </Typography>
                                  </Typography>
                                </Box>
                                
                             </CardContent>
                          </Card>
                          <Card variant="plain" sx={{background: 'none', pb: 1}}>
                            <CardContent>
                              <Box display="flex" alignItems="center" mb={1}>
                                <Avatar sx={{mr: 2, bgcolor:'#fbfcfe', border: '1px solid #00a8aa', width: '46px', height: '46px' }} ><LaptopIcon sx={{ color: '#008e8f', fontSize: '27px'}} /></Avatar>
                                <Typography level="title-md" sx={{ color: '#444', fontWeight: 'bold', fontSize: '17px' }}>
                                  Tech, Travel & Money Tips
                                  <Typography sx={{ fontSize: '15px', color: '#444'}}>
                                  Stay updated on the latest trends, advice, and reviews to enrich your lifestyle.
                                </Typography>
                                </Typography>
                              </Box>
                             
                            </CardContent>
                          </Card>
                          <Card variant="plain" sx={{background: 'none', pb: 1}}>
                            <CardContent>
                              <Box display="flex" alignItems="center" mb={1}>
                               
                                <Avatar sx={{mr: 2, bgcolor:'#fbfcfe', border: '1px solid #00a8aa', width: '46px', height: '46px' }} ><BookmarkIcon sx={{ color: '#008e8f', fontSize: '27px'}} /></Avatar>
                                <Typography level="title-md" sx={{ color: '#444', fontWeight: 'bold', fontSize: '17px' }}>
                                  Save Your Bucket List
                                  <Typography sx={{ fontSize: '15px', color: '#444'}}>
                                    Bookmark must-visit places and ideas to revisit anytime, ensuring you never miss out.
                                  </Typography>
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                          
                          <Card variant="plain" sx={{background: 'none', pb: 1}}>
                              <CardContent>
                              <Box display="flex" alignItems="center" mb={1}>
                                <Avatar sx={{mr: 2, bgcolor:'#fbfcfe', border: '1px solid #00a8aa', width: '46px', height: '46px' }} ><CalendarTodayIcon sx={{ color: '#008e8f', fontSize: '27px'}} /></Avatar>
                                <Typography level="title-md" sx={{ color: '#444', fontWeight: 'bold', fontSize: '17px' }}>
                                  Your Personal Planner
                                  <Typography sx={{ fontSize: '15px', lineHeight: '1.5', color: '#444'}}>
                                  Keep all your favorite finds in one place, making it easy to plan and explore effortlessly.
                                </Typography>
                                </Typography>
                              </Box>
                              
                            </CardContent>
                          </Card>

                        </Box>
                      </Box>
                    </CardOverflow> 
                  </Card>
                </Grid>
              </Grid> 
             
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default SignIn;
