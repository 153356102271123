// src/components/CodeBlock.js
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ language, code }) => (
  <SyntaxHighlighter language={language} style={solarizedlight}>
    {code}
  </SyntaxHighlighter>
);

export default CodeBlock;
