import React from 'react';
import { Pagination, Box } from '@mui/material';
import { styled } from '@mui/system';

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#00a8aa !important',
    color: 'white',
    border: '1px solid #00a8aa',
    fontWeight: 'bold', 
    '&:hover': {
      backgroundColor: '#009799 !important', // slightly darker hover effect
    }
  },
}));

const PaginationBlock = ({ total_pages, active_page, city_id, sub_category_id, inner_category_id, onPageChange }) => {
  const onPageChangef = (event, page_number) => {
    onPageChange(city_id, sub_category_id, inner_category_id, page_number);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
      <CustomPagination
        count={total_pages}
        page={active_page}
        onChange={onPageChangef}
        size="large"
        variant="outlined"
        shape="rounded"
        siblingCount={1}
        boundaryCount={2}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export default PaginationBlock;
