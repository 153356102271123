// src/components/BlogPost.js
import React from 'react';
import { Paper, Typography } from '@mui/material';

const BlogPost = ({ title, content }) => (
  <Paper style={{ padding: '20px', margin: '10px', width: '100%', maxWidth: '600px' }}>
    <Typography variant="h5" component="h2" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body1">{content}</Typography>
  </Paper>
);

export default BlogPost;
