import React, { useState, useEffect } from 'react';
import {
  Container,
} from '@mui/material';
import axios from 'axios';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Banner from '../components/Banners';
import Category from '../components/Category';
import Trending from '../components/Trending';
import WhatsNewToday from '../components/WhatsNewToday';
import Video from '../components/Video'
import Technology from '../components/Technology';
import Health from '../components/Health';
import Money from '../components/Money';
import Entertainment from '../components/Entertainment';
import Travel from '../components/Travel';
import Food from '../components/Food';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
const HomePage = () => {
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [loader, setLoader] = useState(true);
  const [banners, setBanners] = useState(null);
  const [categories, setCategories] = useState(null);
  const [trandingblock, setTranding] = useState(null);
  const [entertainmentblock, setEntertainmentList] = useState(null);
  const [whatstoday, setWhatsToday] = useState(null);
  const [videosblock, setVideos] = useState(null);
  const [technologyblock, setTechnology] = useState(null);
  const [healthblock, setHealth] = useState(null);
  const [moneyblock, setMoney] = useState(null);
  const [trvelblock, setTravel] = useState(null);
  const [foodblock, setFood] = useState(null);
  const [pagefooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const fetchHomePageData = async () => {
    try {
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?home-page-data');

      document.title = "Broadway Originals | Home";
      
      if (response.data.navlist && response.data.socailmedia) {
        setNavlist(
          <Header 
            navlist={JSON.stringify(response.data.navlist)} 
            socailmedia={JSON.stringify(response.data.socailmedia)} 
          />
        );
        setPageMetaTags(response.data.meta_tags);
        setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)}  />);
        setBanners(<Banner bannerslist={JSON.stringify(response.data.banners)}></Banner>);
        setCategories(<Category nav_list={JSON.stringify(response.data.navlist)}></Category>);
        setTranding(<Trending trandingdata={JSON.stringify(response.data.trending)}></Trending>);
        setWhatsToday(<WhatsNewToday todaynews={JSON.stringify(response.data.todaynews)}></WhatsNewToday>);
        setVideos(<Video videoslist={JSON.stringify(response.data.videoslist)}></Video>);
        setEntertainmentList(<Entertainment entertainmentlist={JSON.stringify(response.data.entertainment)}></Entertainment>);
        
        setTechnology(<Technology todaynews={JSON.stringify(response.data.technology)}></Technology>);
        setTravel(<Travel data={JSON.stringify(response.data.travel)}></Travel>);
        setHealth(<Health todaynews={JSON.stringify(response.data.health)}></Health>);
        setFood(<Food foodlist={JSON.stringify(response.data.food)}></Food>);
        setMoney(<Money todaynews={JSON.stringify(response.data.money)}></Money>);
        setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
      } else {
        console.error("Navlist or social media data is missing.");
      }
      
      setLoader(false);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchHomePageData();
  }, []); // Empty dependency array ensures it runs only on mount

  if (loader) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  return (
    <div>
      <Container maxWidth="false" sx={{padding: "0px !important", xs: "16px"}}>
        {navlist}
        <MetaTags tags={pagemetatags} />
        {mainnavlist}
        {banners}
        <Container maxWidth="lg" sx={{padding: { lg: "0px !important" }, mt: {lg: 5, xs: 2}}}>
          {categories}
          {trandingblock}
          {whatstoday}
        </Container>
        {videosblock}
        <Container maxWidth="lg" sx={{padding: { lg: "0px !important"}, mt: 5}}>
          {entertainmentblock}
          {technologyblock}
          {trvelblock}
          {healthblock}
          {foodblock}
          {moneyblock}
        </Container>
        {pagefooter}
      </Container>
    </div>
  );
};

export default HomePage;
