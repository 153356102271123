import React from 'react';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cityguides from '../images/city-guide.svg';
import lifestyle from '../images/Lifestyle.svg';
import { useMediaQuery, useTheme } from '@mui/material';

// Background styles
const waveBackgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(135deg, rgba(240, 244, 248, 0.8), rgba(226, 234, 252, 0.8))',
  zIndex: -1,
  overflow: 'hidden',
};

// Animation keyframes
const styles = `
  @keyframes pulse {
    0% { transform: scale(1); }
    100% { transform: scale(1.05); }
  }
  @keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-30px); }
    100% { transform: translateY(0); }
  }
  @keyframes dot-float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
  }
`;

const Category = ({ nav_list }) => {
  const nav_list_json = JSON.parse(nav_list);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onclickOpenurl = (pageurl) => {
    window.location = pageurl;
  };

  const sliderCategorySettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    ],
  };

  // Desktop rendering
  const renderDesktopItems = (data) => (
    data.map((category) => (
      category.data && (category.category_name === 'City Guides' || category.category_name === 'Life Style') && (
        <Grid item xs={12} sm={12} md={6} key={category.category_name} sx={{ mb: 3 }}>
          <Box sx={{ textAlign: 'left', mb: 5, transition: 'transform 0.5s', '&:hover': { transform: 'scale(1.05)' } }}>
            <Typography variant="h5" sx={{ alignItems: "center", display: "flex", fontWeight: "bold" }}>
              <Avatar
                src={category.category_name === 'City Guides' ? cityguides : lifestyle}
                alt={category.category_name}
                sx={{
                  display: 'inline-block', mr: 1, borderRadius: "0px",
                  transition: 'transform 1s', transformStyle: 'preserve-3d',
                  '&:hover': { transform: 'rotateY(360deg)' }
                }}
              />
              {category.category_name}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {(category.category_name === 'City Guides' ? category.data.cities : category.data.sub_categories).map((item) => (
              <Grid item xs={6} sm={3} key={item.name}>
                <Box sx={{ textAlign: 'center' }}>
                  <Avatar
                    src={`https://broadwayoriginals.com/${item.image || item.img}`}
                    alt={item.name}
                    sx={{
                      width: '80%', height: 'auto', border: '1px solid #3daaaa', margin: 'auto',
                      transition: 'transform 1s', transformStyle: 'preserve-3d',
                      '&:hover': { transform: 'rotateY(360deg)' }
                    }}
                  />
                  <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}/${item.urlstring || item.custom_url || ''}`)} style={{ cursor: 'pointer' }}>
                    {item.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )
    ))
  );

  // Mobile rendering with slider
  const renderMobileSlider = (data) => (
    data.map((category) => (
      category.data && (category.category_name === 'City Guides' || category.category_name === 'Life Style') && (
        <Grid item xs={12} key={category.category_name} sx={{ mb: 3 }}>
          <Box sx={{ textAlign: 'left', mb: 5, transition: 'transform 0.5s', '&:hover': { transform: 'scale(1.05)' } }}>
            <Typography variant="h5" sx={{ alignItems: "flex-end", display: "flex", fontWeight: "bold" }}>
              <Avatar
                src={category.category_name === 'City Guides' ? cityguides : lifestyle}
                alt={category.category_name}
                sx={{
                  display: 'inline-block', mr: 1, borderRadius: "0px",
                  transition: 'transform 1s', transformStyle: 'preserve-3d',
                  '&:hover': { transform: 'rotateY(360deg)' }
                }}
              />
              {category.category_name}
            </Typography>
          </Box>
          <Slider {...sliderCategorySettings}>
            {(category.category_name === 'City Guides' ? category.data.cities : category.data.sub_categories).map((item) => (
              <Box key={item.name} sx={{ textAlign: 'center' }}>
                <Avatar
                  src={`https://broadwayoriginals.com/${item.image || item.img}`}
                  alt={item.name}
                  sx={{
                    width: '70%', height: 'auto', border: '1px solid #3daaaa', margin: 'auto',
                    transition: 'transform 1s', transformStyle: 'preserve-3d',
                    '&:hover': { transform: 'rotateY(360deg)' }
                  }}
                />
                <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}/${item.urlstring || item.custom_url || ''}`)} style={{ cursor: 'pointer' }}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Grid>
      )
    ))
  );

  return (
    <>
      <style>{styles}</style>
      <Grid container spacing={{ xs: 2, md: 20, pt: 5 }}>
        {!isMobile ? renderDesktopItems(nav_list_json) : renderMobileSlider(nav_list_json)}
      </Grid>
    </>
  );
};

export default Category;
