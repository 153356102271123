import React from 'react';
import Section from './HomePost';
import wht_img from '../images/whats-new-today.svg';
function WhatsNewToday({ todaynews }) {
  const todaynews_list = JSON.parse(todaynews);

  return (
    <>
      {todaynews_list.length > 0 && (
        <Section
          title="What’s New Today"
          image={wht_img}
          newsData={todaynews_list}
          sectionUrl="/today-news"
        />
      )}
    </>
  );
}

export default WhatsNewToday;
