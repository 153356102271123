import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function OurGallery(gallerydata) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const itemData = JSON.parse(gallerydata.gallerydata);
  return (
    <ImageList
      sx={{ width: '100%', height: 450 }}
      cols={isSmallScreen ? 1 : isMediumScreen ? 2 : 4} // Adjust columns based on screen size
      rowHeight={164}
    >
      {itemData.length > 0 && itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt="gallery image"
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}