import React, { useState, useEffect } from 'react';
import {
  Container,
} from '@mui/material';
import axios from 'axios';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import HomeComponent from '../components/HomeComponent';
import Footer from '../components/Footer';
const HomePage = () => {
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [loader, setLoader] = useState(true);
  const [homeomponent_content, setHomeComponentContent] = useState(null);
  const [pagefooter, setPageFooter] = useState(null);
  const fetchHomePageData = async () => {
    try {
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?home-page-data');

      document.title = "Broadway Originals | Home";
      
      if (response.data.navlist && response.data.socailmedia) {
        setNavlist(
          <Header 
            navlist={JSON.stringify(response.data.navlist)} 
            socailmedia={JSON.stringify(response.data.socailmedia)} 
          />
        );
        setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)}  />);
        setHomeComponentContent(<HomeComponent componentdata= {JSON.stringify(response.data)}></HomeComponent>);
        setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
      } else {
        console.error("Navlist or social media data is missing.");
      }
      
      setLoader(false);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchHomePageData();
  }, []); // Empty dependency array ensures it runs only on mount

  if (loader) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  return (
    <div>
      <Container maxWidth="false" sx={{padding: "0px !important"}}>
        {navlist}
        {mainnavlist}
        {homeomponent_content}
        {pagefooter}
      </Container>
    </div>
  );
};

export default HomePage;
