import { Grid, TextField, Card, CardContent, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';

function CommentSection({ userpostcomment, handleWriteComment, commentPost, postcomments }) {
  return (
    <Grid container spacing={2} sx={{mt: 2}}>
      {/* Comment Input Section */}
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              placeholder="Comment"
              value={userpostcomment}
              onChange={handleWriteComment}
            />
          </Grid>
          <Grid item xs={1} sx={{textAlign: 'end'}}>
            <IconButton onClick={commentPost} className="post-send-btn">
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {/* Comments Display Section */}
      <Grid item xs={12} md={12}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Comments
        </Typography>
        <Card elevation={0} sx={{ border: "1px solid #E5E5E5", background: "none", borderRadius: "16px", p: 0, }}>
          <CardContent>
            <List dense sx={{width: '100%'}}>
              {postcomments !== "" && postcomments !== "[]" && postcomments !== "null" && (
                postcomments.map((commentdetails, index) => (
                  <ListItem key={index} sx={{ padding: "10px 0" }}>
                    <ListItemText
                      primary={commentdetails.comment}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CommentSection;
