// src/components/SocialShare.js
import React from 'react';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import { Box } from '@mui/material';

const SocialShare = ({ url }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
    <FacebookShareButton url={url}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <TwitterShareButton url={url}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>
  </Box>
);

export default SocialShare;
