import React from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Badge,
  IconButton,
} from '@mui/material';
import Iserve from '../images/recipes/img_iserve.svg';
import Itime from '../images/recipes/img_itime.svg';
import Istar from '../images/recipes/img_istar.svg';
import Iprinter from '../images/recipes/img_i_printer.svg';

const RecipeBlock = ({ recipes, handlePrint, printRef }) => {
  return (
    <Box
      id="print_recipe_block"
      ref={printRef}
      sx={{ backgroundColor: 'none' }}
    >
      {recipes && recipes.length > 0 && (
        <Card
          variant="elevation"
          sx={{
            borderRadius: 2,
            padding: 3,
            boxShadow: 3,
            backgroundColor: 'none !important',
            background: 'none !important',
          }}
        >
          {recipes.map((recipe, index) => (
            <Box key={index} sx={{ marginBottom: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: '700',
                  color: '#333',
                  marginBottom: 1,
                }}
              >
                {recipe.recipe_name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2, flexWrap: 'wrap', mb: 1}}>
                  <img src={Iserve} alt="Serves" />
                  <Typography
                    sx={{
                      color: '#757575',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: 1,
                      
                    }}
                  >
                    Serves: {recipe.serves}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                  <img src={Itime} alt="Cooking time" />
                  <Typography
                    sx={{
                      color: '#757575',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: 1,
                    }}
                  >
                    Cooking time: {recipe.cooking_time}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                  <img src={Istar} alt="Effort" />
                  <Typography
                    sx={{
                      color: '#757575',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: 1,
                    }}
                  >
                    Effort: {recipe.effort}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  sx={{
                    backgroundColor: 'none',
                    border: '1px solid #d5d5d5',
                    borderRadius: '5px',
                    color: '#333',
                    '&:hover': { backgroundColor: '#d5d5d5' },
                  }}
                  onClick={handlePrint}
                >
                  <img src={Iprinter} style={{ width: '18%' }} alt="Print" />
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    Print Recipe
                  </Typography>
                </IconButton>
              </Box>

              <Table
                sx={{
                  borderCollapse: 'collapse',
                  marginBottom: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: 1,
                        backgroundColor: '#fafafa',
                        color: '#333',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      Ingredients
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recipe.ingredients &&
                    recipe.ingredients.map((ingredient, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            color: '#333',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '400',
                            padding: 1,
                            backgroundColor: index % 2 === 0 ? '#f3f3f3' : 'transparent',
                          }}
                        >
                          {ingredient.ingredient}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Card>
      )}
    </Box>
  );
};

export default RecipeBlock;
