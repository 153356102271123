import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Paper,
  Button,
} from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import AuthorCard from '../components/AuthorCard';
import MembersGallery from './MembersGallery';
import OurGallery from './OurGallery';

const About = () => {
  
  const [data, setData] = useState(null);
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [pageMembers, setPageMembers] = useState(null);
  const [pagecontent, setPageContent] = useState(null);
  const [pagegallery, setPageGallery] = useState([]);
  const [pageteammembers, setPageTeamMembers] = useState([]);
  const onclickOpenURL = (pageurl) => {
      window.location = "/"+pageurl;
  }
  const fetchPageData = async () => {
        try {
        setLoaderState(true);
        const response = await axios.get('https://broadwayoriginals.com/apis/index.php?about-us');
        document.title = "Broadway Originals | About Us";
        
        setData(response.data);
        setNavlist(
            <Header 
              navlist={JSON.stringify(response.data.navlist)} 
              socailmedia={JSON.stringify(response.data.socailmedia)} 
            />
          );
        setMainNavlist(<MainNav navlist={JSON.stringify(response.data.navlist)} />);
        setPageMetaTags(response.data.meta_tags);
        setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)} />);
      
        if(response.data.pagedata.members !==""){
            setPageMembers(response.data.pagedata.members); 
        }
        if(response.data.pagedata.gallery !==""){
          setPageGallery(response.data.pagedata.gallery);
        }
        if(response.data.pagedata.team_members !==""){
          setPageTeamMembers(response.data.pagedata.team_members);
        }
        
        setLoaderState(false);
        } catch (error) {
        console.error('Error fetching data:', error);
        }
    };
  useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px', color: 'black' }}>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    textAlign: 'center !important',
                    color: 'rgb(0, 123, 124)',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    transition: 'transform 0.3s ease',
                    transformStyle: 'preserve-3d',
                    '&:hover': {
                      transform: 'rotateY(10deg) rotateX(10deg)',
                    },
                  }}
                >
                  About Us
                </Typography>
                <Typography sx={{textAlign: 'center'}}>
                Welcome to our diverse blogger website, your one-stop destination for a wide array of content ranging from insightful movie reviews to
 tantalizing food critiques, from the latest updates in various fields to cutting-edge technology insights, and an assortment of engaging
 articles to keep you informed and entertained. 
                </Typography>
                
            <AuthorCard memberslist={JSON.stringify(pageMembers)}></AuthorCard>
            <Typography sx={{textAlign: 'center', mb: 2}}>
            At our core, we're passionate about sharing compelling narratives, sparking discussions, and offering valuable perspectives on the
 topics that matter most to our readers. With a dedicated team of writers who are experts in their respective domains, we strive to
 deliver high-quality, informative, and entertaining content that resonates with audiences of all interests and backgrounds. 
            </Typography>
            <Typography sx={{textAlign: 'center', mb: 2}}>
            Whether you're a cinephile seeking the next must-watch film, a foodie in search of culinary inspiration, a tech enthusiast staying abreast
 of the latest innovations, or simply someone who loves to delve into thought-provoking articles, we invite you to explore our website
 and join us on this enriching journey of discovery and exploration.
            </Typography>
            <Typography sx={{textAlign: 'center', mb: 2}}>
                <MembersGallery memberslist = {JSON.stringify(pageteammembers)}></MembersGallery>
            </Typography>
            <Typography
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    mt: 3
                  }}
                >
                  Our Gallery
                </Typography>
                <Typography sx={{textAlign: 'center', mb: 10}}>
                  <OurGallery gallerydata={JSON.stringify(pagegallery)}></OurGallery>
                </Typography>
                
        </Container>
            

      </Container>
      {pageFooter}
    </>
  );
};

export default About;
