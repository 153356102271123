import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Card,
  CardContent
} from '@mui/material';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const fetchPageData = async () => {
    try {
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
      setLoaderState(false);
      document.title = "Broadway Originals | Change Password";
      const responseData = response?.data || {};
      setNavlist(
        <Header
          navlist={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
      setPageFooter(
        <Footer
          nav_list={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setPageMetaTags(responseData.pagemetatags || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push("Password must be at least 8 characters long.");
    if (!/[A-Z]/.test(password)) errors.push("Password must contain at least one uppercase letter.");
    if (!/[a-z]/.test(password)) errors.push("Password must contain at least one lowercase letter.");
    if (!/[0-9]/.test(password)) errors.push("Password must contain at least one number.");
    if (!/[!@#$%^&*]/.test(password)) errors.push("Password must contain at least one special character.");
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordErrors = validatePassword(newPassword);

    if (passwordErrors.length > 0) {
      setErrors(passwordErrors);
      return;
    } else if (newPassword !== confirmPassword) {
      setErrors(["New passwords don't match."]);
      return;
    } else {
      setErrors([]); // Clear errors if all validations pass
    }

    const userData = localStorage.getItem('user');
    let session_bdo_id = '';

    if (userData) {
      const { expiresAt, customlog } = JSON.parse(userData);
      const currentTime = new Date().getTime();
      const expirationTime = new Date(expiresAt).getTime();
      if (currentTime < expirationTime) {
        session_bdo_id = customlog;
      }
    }

    if (session_bdo_id && errors.length === 0) {
      try {
        const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {
          change_pwd: "",
          user_key: session_bdo_id,
          new_password: newPassword,
          old_password: currentPassword
        });
        
        const response_data = response.data;
        if (response_data.success) {
          toast.success(response_data.message);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          toast.error(response_data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const message = error.response.data?.message || "Unauthorized access. Please log in.";
          toast.error(message);
        } else {
          console.error('Error changing password:', error);
          toast.error("An error occurred while trying to change the password.");
        }
      } 
    } else {
      toast.error("Please login to change password.");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { username, expiresAt } = JSON.parse(userData);
      if (expiresAt && new Date(expiresAt) > new Date()) {
        fetchPageData();
      } else {
        window.location = "/signin";
      }
    } else {
      window.location = "/signin";
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100%',
          color: '#fff',
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, mt: 5, mb: 5, color: 'black', minHeight: '50vh' }}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h4" component="h6" sx={{ color: '#008989', fontWeight: 'bold', fontSize: '30px' }}>
                Change Password
              </Typography>
              <Card sx={{ maxWidth: 400, mt: 2, background: 'none', boxShadow: 'none', p: 0, m:0 }}>
                <CardContent sx={{p: 0, mt: 2}}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Current Password"
                          type="password"
                          fullWidth
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="New Password"
                          type="password"
                          fullWidth
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Confirm New Password"
                          type="password"
                          fullWidth
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </Grid>
                      {errors.length > 0 && (
                        <Grid item xs={12}>
                          {errors.map((error, index) => (
                            <Typography key={index} color="error" variant="body2">
                              {error}
                            </Typography>
                          ))}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" sx={{ background: '#008988' }} fullWidth>
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default ChangePassword;
