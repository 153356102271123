import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Card, CardMedia, CardContent, Typography, Link, IconButton, CircularProgress, Avatar, Badge, Button } from '@mui/material';
import Slider from "react-slick";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Material-UI icons for arrows
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import '../css/styles.css'; // Import your styles
import cityguides from '../images/city-guide.svg';
import lifestyle from '../images/Lifestyle.svg';
import trendingIcon from '../images/trending-icon.png';
import wht_img from '../images/whats-new-today.svg';
import food_icon from "../images/food.png";
import travelicon from "../images/travel.png";
function HomeComponent({ componentdata }) {
  const [loading, setLoading] = useState(true);
  const [componentdata_json, setComponentData] = useState(null);
  const baseUrl = "https://broadwayoriginals.com/";
  const onclickOpenurl = (pageurl) => {
    window.location = pageurl;
  };
  // Fetch and parse data
  useEffect(() => {
    try {
      const data = JSON.parse(componentdata);
      setComponentData(data);
    } catch (error) {
      console.error("Failed to parse component data:", error);
    } finally {
      setLoading(false);
    }
  }, [componentdata]);

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Container>
    );
  }

  // Data Destructuring
  const {
    banners: banners_list = [],
    navlist: categories_data = [],
    trending: trending_list = [],
    todaynews: todaynews_list = [],
    videoslist: videos_list = [],
    technology: technology_data = [],
    travel: travel_data = [],
    health: health_data = [],
    food: food_data = [],
    money: money_data = []
  } = componentdata_json || {};

  // Custom Next Arrow
  const NextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        backgroundColor: '#ffffff',
        '&:hover': { backgroundColor: '#f0f0f0' },
      }}
    >
      <ArrowForwardIos />
    </IconButton>
  );

  // Custom Previous Arrow
  const PrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        backgroundColor: '#ffffff',
        '&:hover': { backgroundColor: '#f0f0f0' },
      }}
    >
      <ArrowBackIos />
    </IconButton>
  );

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // Tranding Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const onclickOpenTravel = () => {
    window.location = "/travel";
  };
  const onclickOpenTodayNews = () => {
    window.location = "/today-news";
  };
  const onclickOpenFood = () => {
    window.location = "/food";
  };
  const BlockSection = ({ title, data, isVideo }) => {
    // Manage video state for the main item
    const [playVideo, setPlayVideo] = useState(false);

    // Handle play video action for the main item
    const handleVideoPlay = () => {
        setPlayVideo(!playVideo);
    };

    return (
        <Box sx={{ my: 6 }}>
            <Container maxWidth="lg" sx={{ mb: 5, paddingX: { xs: 2, sm: 3 } }}>
                {/* Header Section */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={10}>
                        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', color: '#00a8a9', fontSize: { xs: '24px', md: '32px' } }}>
                            <Avatar src={wht_img} alt={title} sx={{ marginRight: 2, borderRadius: "0px" }} />
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
                        <Badge
                            sx={{
                                fontSize: "18px",
                                background: "white",
                                color: "#00a8a9",
                                borderRadius: "20px",
                                padding: "10px",
                                cursor: "pointer",
                            }}
                            onClick={() => window.location = `/${title.toLowerCase()}`}
                        >
                            <span style={{ color: "#00a8a9", fontWeight: 200 }}>
                                Explore More <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "15px", paddingLeft: "10px" }} />
                            </span>
                        </Badge>
                    </Grid>
                </Grid>

                {/* Main Content */}
                <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                    {/* Main Block (First Item) */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {data.map((item, index) => index === 0 && (
                            <Card key={item.id} sx={{ border: "none", borderRadius: "20px", mb: 2, background: "transparent", boxShadow: 'none', display: 'flex', flexDirection: 'column', flex: 1 }}>
                                {isVideo && playVideo ? (
                                    <Box sx={{ p: 2 }}>
                                        <iframe
                                            width="100%"
                                            height="350"
                                            src={item.video_link}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                ) : (
                                    <CardMedia
                                        component="img"
                                        image={`${baseUrl}${item.post_img}`}
                                        loading="lazy"
                                        onClick={() => isVideo && handleVideoPlay()}
                                        sx={{
                                            borderRadius: "20px",
                                            height: { xs: '240px', md: '357px' },
                                            objectFit: 'cover',
                                            cursor: isVideo ? 'pointer' : 'default',
                                        }}
                                    />
                                )}
                                <CardContent sx={{ padding: "0", mt: 1, background: "transparent", flexGrow: 1 }}>
                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={`${baseUrl}${item.author.img}`} alt={item.author.name} sx={{ width: 30, height: 30, mr: 1 }} />
                                        <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        component={NavLink}
                                        to={item.custom_url}
                                        sx={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            fontWeight: 'bold',
                                            marginTop: '8px',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {item.post_title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>

                    {/* Secondary Block (Other Items) */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {data.map((item, index) => index !== 0 && (
                            <Card key={item.id} sx={{ display: 'flex', mb: 2, borderRadius: '20px', flexDirection: 'row', background: "transparent", boxShadow: 'none', flexGrow: 1 }}>
                                <CardMedia
                                    component="img"
                                    image={`${baseUrl}${item.post_img}`}
                                    alt={item.post_title}
                                    sx={{
                                        width: { xs: '120px', sm: '151px' },
                                        height: 'auto',
                                        borderRadius: '20px',
                                    }}
                                />
                                <CardContent sx={{ flex: '1 0 auto', padding: 1, background: "transparent", boxShadow: 'none' }}>
                                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={`${baseUrl}${item.author.img}`} alt={item.author.name} sx={{ width: 30, height: 30, mr: 1 }} />
                                        <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        component={NavLink}
                                        to={item.custom_url}
                                        sx={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            fontWeight: 'bold',
                                            marginTop: '8px',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {item.post_title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
  
  const renderItems = (data) => {
    return data.map((category) => (
      category.data && (category.category_name === 'City Guides' || category.category_name === 'Life Style') && (
        <Grid item xs={12} sm={12} md={6} key={category.category_name} sx={{ mb: 3 }}>
          <Box sx={{ textAlign: 'left', mb: 5, transition: 'transform 0.5s', '&:hover': { transform: 'scale(1.05)' } }}>
            <Typography variant="h5" sx={{ alignItems: "center", display: "flex", fontWeight: "bold", textAlign: 'left' }}>
              <Avatar
                src={category.category_name === 'City Guides' ? cityguides : lifestyle}
                alt={category.category_name}
                sx={{
                  display: 'inline-block',
                  mr: 1,
                  borderRadius: "0px",
                  transition: 'transform 1s',
                  transformStyle: 'preserve-3d',
                  '&:hover': {
                    transform: 'rotateY(360deg)'
                  }
                }}
              />
              {category.category_name}
            </Typography>
          </Box>
          {category.category_name === 'City Guides' && (
            <Grid container spacing={2}>
              {category.data.cities.map((city) => (
                <Grid item xs={6} sm={3} key={city.name}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${city.image}`}
                      alt={city.name}
                      sx={{
                        width: '80%',
                        height: 'auto',
                        border: '1px solid #3daaaa',
                        margin: 'auto',
                        transition: 'transform 1s',
                        transformStyle: 'preserve-3d',
                        '&:hover': {
                          transform: 'rotateY(360deg)'
                        }
                      }}
                    />
                    <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}/${city.urlstring}`)} style={{ cursor: 'pointer' }}>
                      {city.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          {category.category_name === 'Life Style' && (
            <Grid container spacing={2}>
              {category.data.sub_categories.map((sub_category) => (
                <Grid item xs={6} sm={3} key={sub_category.name}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${sub_category.img}`}
                      alt={sub_category.name}
                      sx={{
                        width: '80%',
                        height: 'auto',
                        border: '1px solid #3daaaa',
                        margin: 'auto',
                        transition: 'transform 1s',
                        transformStyle: 'preserve-3d',
                        '&:hover': {
                          transform: 'rotateY(360deg)'
                        }
                      }}
                    />
                    <Typography sx={{ mt: 1 }} onClick={() => onclickOpenurl(`/${category.custom_url}`)} style={{ cursor: 'pointer' }}>
                      {sub_category.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      )
    ));
  };
  const CardComponent = ({ title, content }) => (
    <Card 
      sx={{ 
        boxShadow: 3, 
        width: '100%',  // Ensure it takes the full width of the grid item
        height: '150px',  // Set a fixed height for the rectangular appearance
        '&:hover': { transform: 'scale(1.03)', transition: '0.3s' } 
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
        <Typography variant="h6" fontWeight="bold">{title}</Typography>
        {content}
      </CardContent>
    </Card>
  );
  return (
    <Container maxWidth="false" sx={{ backgroundColor: '#f9f9f9', padding: "0px !important", margin: "0px !important" }} >

      {/* Banner Section */}
      <Box sx={{ width: '100%', padding: "0px", margin: "0px" }}>
        <Slider {...settings}>
          {banners_list.map((banner, index) => (
            <Box key={index} sx={{ position: 'relative', width: '100%', padding: "0px", margin: "0px" }}>
              <img
                src={`${baseUrl}${banner.image}`}
                alt={banner.name}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '600px',
                  display: 'block',
                  objectFit: 'cover',
                }}
              />
              {/* Banner name overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '5px 10px',
                  borderRadius: '5px',
                }}
              >
                <Typography variant="subtitle1" sx={{ color: 'white', textAlign: 'center' }}>
                  {index + 1}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Main Content Container */}
      <Container maxWidth="lg" sx={{ padding: "0 20px" }} >
      
        {/* Categories */} 
        <Box  sx={{my: 6}}>
            <Grid container spacing={{ xs: 2, md: 20, pt: 5 }} >
                {renderItems(categories_data)}
            </Grid>
        </Box> 

        {/* Trending Section */}
        <Box sx={{ mt: 1, mb: 8, position: 'relative' }}>
            <Container maxWidth="lg">
                <Grid container justifyContent="center" sx={{ mb: 4 }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <img src={trendingIcon} alt="Trending Icon" style={{ marginRight: '8px' }} />
                    <Typography variant="h4" sx={{ color: '#00A8AA', fontWeight: 'bold' }}>
                        Trending
                    </Typography>
                    </Box>
                </Grid>
                </Grid>

                <Slider {...sliderSettings}>
                {trending_list.map((item, index) => (
                    <Box key={index} sx={{ p: 2 }}>
                    <Card sx={{ borderRadius: '20px', position: 'relative', width: '100%', height: '330px' }}>
                        <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item.post_img}`}
                        alt={item.post_title}
                        sx={{ height: '100%', borderRadius: '20px' }}
                        />
                        <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(33, 37, 41, 0.55)',
                            borderRadius: '20px',
                            padding: '10px',
                        }}
                        >
                        <Typography
                            variant="h6"
                            component={Link}
                            to={`/${item.custom_url}`}
                            sx={{
                            color: 'white',
                            textDecoration: 'none',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            }}
                        >
                            {item.post_title}
                        </Typography>
                        </Box>
                    </Card>
                    </Box>
                ))}
                </Slider>
            </Container>
        </Box>
        

        {/* Today News Section */}
        <BlockSection title="What’s New Today" data={todaynews_list} isVideo={false} />

        {/* Videos Section */}
        <BlockSection title="Videos" data={videos_list} isVideo={true} />
       

        {/* Technology Section */}
        <BlockSection title="Technology" data={technology_data} isVideo={false} />

        {/* Travel Section */}
        <Box >
        <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={10}>
          <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={travelicon} alt="Travel" style={{ marginRight: 10 }} loading="lazy" />
            Travel
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: 'end', padding: 0 }}>
          {/* Unique Explore More Button */}
          <Button
            onClick={onclickOpenTravel}
            variant="contained"
            sx={{
              width: "100%",
              background: "linear-gradient(45deg, #00a8a9, #00796b)",
              borderRadius: "30px",
              padding: "12px 16px",
              borderRadius: "20px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              transition: "transform 0.3s ease",
              lineHeight: "1", // Set line height to 1
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)"
              },
              "&:active": {
                transform: "translateY(0px)",
                boxShadow: "none"
              }
            }}
          >
            <span>Explore More</span>
            <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "10px", transition: "transform 0.3s" }} />
          </Button>
        </Grid>
      </Grid>

      {/* Travel Content Section */}
      <Grid container spacing={4} sx={{ marginTop: 1 }}>
            {travel_data.map((item1, index) => (
            <Grid 
                item 
                xs={12} 
                md={travel_data.length === 1 ? 12 : 6}  // Full width if only one post, else half width
                key={index}
            >
                <Card
                sx={{
                    borderRadius: "21px",
                    border: "none",
                    position: 'relative',
                    transition: "transform 0.4s ease, box-shadow 0.4s ease",  // Smooth transition
                    "&:hover": {
                    transform: "scale(1.05)",  // Scale on hover
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)"  // Add shadow on hover
                    },
                }}
                >
                <CardMedia
                    component="img"
                    image={`https://broadwayoriginals.com/${item1.post_img}`}
                    alt={item1.post_title}
                    sx={{
                    borderRadius: "20px",
                    height: travel_data.length === 1 ? "300px" : "100%",  // Set a fixed height for a single post
                    maxHeight: "300px",  // Limit the height for large images
                    objectFit: "cover"  // Ensure image scales nicely
                    }}
                    loading="lazy"
                />
                <Box
                    sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.33)",
                    borderRadius: "21px",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                    }}
                >
                    <NavLink to={`/${item1.custom_url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography
                        variant="h6"
                        sx={{
                        color: 'white',
                        fontWeight: travel_data.length === 1 ? 'bold' : 'normal',
                        fontSize: travel_data.length === 1 ? '1.5rem' : '1rem',  // Larger font when only one post
                        }}
                    >
                        {item1.post_title}
                    </Typography>
                    </NavLink>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 2 }}>
                    <Avatar
                        src={`https://broadwayoriginals.com/${item1.author.img}`}
                        alt={item1.author.name}
                        sx={{ width: 40, height: 40, marginRight: 1 }}
                    />
                    <Typography variant="body2" sx={{ color: 'white' }}>
                        {item1.author.name}
                    </Typography>
                    </Box>
                </Box>
                </Card>
            </Grid>
            ))}
        </Grid>

        {/* Mobile View "Explore More" Button */}
        <Grid container justifyContent="flex-end" sx={{ marginTop: 4, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={12}>
            <Button
                onClick={onclickOpenTravel}
                variant="contained"
                sx={{
                width: "100%",
                background: "linear-gradient(45deg, #00a8a9, #00796b)",
                borderRadius: "30px",
                padding: "6px 16px",  // Updated padding
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
                transition: "transform 0.3s ease",
                lineHeight: "1", // Set line height to 1
                "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)"
                },
                "&:active": {
                    transform: "translateY(0px)",
                    boxShadow: "none"
                }
                }}
            >
                <span>Explore More</span>
                <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "10px", transition: "transform 0.3s" }} />
            </Button>
            </Grid>
        </Grid>
        </Box>

        {/* Health Section */}
        <BlockSection title="Health" data={health_data} isVideo={false} />

        {/* Food Section */}
        <Box sx={{ my: 6 }}>
        {food_data.length > 0 && (
            <Container sx={{ marginBottom: "60px", paddingX: { xs: 2, sm: 3 } }}>
            {/* Header Section */}
            <Grid container sx={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={10}>
                <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '24px', md: '32px' } }}>
                    <img src={food_icon} alt="Food" style={{ marginRight: 10 }} loading="lazy" />
                    Food
                </Typography>
                </Grid>
                <Grid item xs={12} md={2} sx={{ textAlign: 'end' }} className='show-on-desktop'>
                <Badge
                    sx={{
                    fontSize: "18px",
                    backgroundColor: "white",
                    color: "#00a8a9",
                    borderRadius: "20px",
                    padding: "10px",
                    cursor: "pointer",
                    border: "1px solid #00a8a9",
                    paddingLeft: "25px",
                    }}
                    onClick={onclickOpenFood}
                >
                    <span style={{ color: "#00a8a9", fontWeight: 200 }}>
                    Explore More <FontAwesomeIcon icon={faChevronRight} style={{ paddingLeft: "10px" }} />
                    </span>
                </Badge>
                </Grid>
            </Grid>

            {/* Main Content Section for Desktop */}
            <Grid container spacing={2} sx={{ marginTop: "45px", display: { xs: 'none', md: 'flex' } }}>
                {food_data.map((item, index) => (
                <Grid item xs={12} md={3} key={index}>
                    <Card sx={{ backgroundColor: 'dark', color: 'white', height: '100%', position: 'relative' }}>
                    <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item.img}`}
                        alt={item.name}
                        sx={{ height: '100%', borderRadius: '20px', objectFit: 'cover' }}
                        loading="lazy"
                    />
                    <Box
                        sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better visibility
                        padding: 1,
                        }}
                    >
                        <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                            {item.name}
                        </Typography>
                        </NavLink>
                    </Box>
                    </Card>
                </Grid>
                ))}
            </Grid>

            {/* Carousel for Mobile */}
            <Grid className="hide-on-desktop">
                <Slider {...settings} sx={{ marginTop: "45px" }}>
                {food_data.map((item, index) => (
                    <Box key={index}>
                    <Card sx={{ backgroundColor: 'dark', color: 'white', height: '100%' }}>
                        <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item.img}`}
                        alt={item.name}
                        sx={{ height: '100%', borderRadius: '20px', objectFit: 'cover' }}
                        loading="lazy"
                        />
                        <Box sx={{ position: 'absolute', bottom: 0, padding: 1 }}>
                        <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                            {item.name}
                            </Typography>
                        </NavLink>
                        </Box>
                    </Card>
                    </Box>
                ))}
                </Slider>
            </Grid>

            {/* Mobile View "Explore More" Button */}
            <Grid container justifyContent="flex-end" sx={{ marginTop: 4, display: { xs: 'flex', md: 'none' } }}>
                <Grid item xs={12}>
                <Badge
                    sx={{
                    fontSize: "18px",
                    background: "none",
                    color: "#00a8a9",
                    borderRadius: "20px",
                    padding: "10px",
                    cursor: "pointer",
                    border: "1px solid #00a8a9",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                    }}
                    onClick={onclickOpenFood}
                >
                    <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Badge>
                </Grid>
            </Grid>
            </Container>
        )}
          
        </Box>

        {/* Money Section */}
        <BlockSection title="Money" data={money_data} isVideo={false} />
       

      </Container>
    </Container>
  );
}

export default HomeComponent;
