// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePage from './pages/HomePage';
import CityGuides from './pages/CityGuides';
import LifeStyle from './pages/LifeStyle';
import Travel from './pages/Travel';
import Food from './pages/Food';
import Videos from './pages/Videos';
import Entertainment from './pages/Entertainment';
import Health from './pages/Health';
import Technology from './pages/Technology';
import Money from './pages/Money';
import About from './pages/About';
import Policy from './pages/Policy'
import Withus from './pages/Withus'
import PostPage from './pages/PostPage';
import Contact from './pages/Contact';
import Advertise from './pages/Advertise';
import CreatePostPage from './pages/CreatePostPage';
import PostView from './pages/PostView';
import SignIn from './pages/SignIn';
import SignUp from  './pages/SignUp';
import Verify from './pages/Verify';
import Dashboard from './pages/Dashboard';
import ChangePassword from './pages/ChangePassword';
import ResetForgotPassword from './pages/ResetForgotPassword';
import TodayNews from './pages/TodayNews';
import { CssBaseline } from '@mui/material';
import Test from './pages/Test';
const App = () => {
  return (
    <Router>
      <CssBaseline /> {/* Ensure consistent styling */}
      <div style={{ width: '100%', margin: 0, padding: 0 }}>
        <Helmet>
          <title>Broadway Way Originals</title>
          <meta name="description" content="A blog about various topics." />
        </Helmet>
        <Routes>
        <Route path="/health" element={<Health />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/money" element={<Money />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/work-with-us" element={<Withus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/create" element={<CreatePostPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/:posturl" element={<PostView />} />
          <Route path="/" element={<HomePage />} />
         
          {/* CityGuides */}
          <Route path="/city-guides/:selectcity/:selectsubcategory/:innercategory" element={<CityGuides />} />
          <Route path="/city-guides/:selectcity/:selectsubcategory" element={<CityGuides />} />
          <Route path="/city-guides/:selectcity" element={<CityGuides />} />          
          <Route path="/city-guides" element={<CityGuides />} />
          {/* LifeStyle */}
          <Route path="/life-style/:selectsubcategory/:innercategory" element={<LifeStyle />} />
          <Route path="/life-style/:selectsubcategory" element={<LifeStyle />} />
          <Route path="/life-style" element={<LifeStyle />} />
          {/* Travel */}
          <Route path="/travel/:selectsubcategory/:innercategory" element={<Travel />} />
          <Route path="/travel/:selectsubcategory" element={<Travel />} />
          <Route path="/travel" element={<Travel />} />
          {/* Food */}
          <Route path="/food/:selectsubcategory/:innercategory" element={<Food />} />
          <Route path="/food/:selectsubcategory" element={<Food />} />
          <Route path="/food" element={<Food />} />
          {/* Viodes */}
          <Route path="/videos/:selectsubcategory/:innercategory" element={<Videos />} />
          <Route path="/videos/:selectsubcategory" element={<Videos />} />
          <Route path="/videos" element={<Videos />} />
          {/* Entertainment */}
          <Route path="/entertainment/:selectsubcategory/:innercategory" element={<Entertainment />} />
          <Route path="/entertainment/:selectsubcategory" element={<Entertainment />} />
          <Route path="/entertainment" element={<Entertainment />} />
          <Route path="/today-news" element={<TodayNews />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/verify/:token/:mail" element={<Verify />} />
          <Route path="/resetpassword/:token/:mail" element={<ResetForgotPassword />} />
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
