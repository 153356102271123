import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button, Avatar, useMediaQuery, useTheme
} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Header from '../components/Header';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import MetaTags from '../components/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [loaderState, setLoaderState] = useState(true);
  const [navlist, setNavlist] = useState(null);
  const [mainnavlist, setMainNavlist] = useState(null);
  const [pageFooter, setPageFooter] = useState(null);
  const [pagemetatags, setPageMetaTags] = useState([]);
  const [fullname, setFullname] = useState('');
  const [fullnameError, setFullnameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [phonenumberError, setPhonenumberError] = useState('');
  const [query, setQuery] = useState('');
  const [queryError, setQueryError] = useState('');
  const [toggle, setToggle] = useState(false);
  const validateFormSequentially = () => {
    // Validate fullname
    if (!fullname) {
      setFullnameError('Please enter full name');
      return false;
    } else {
      setFullnameError('');
    }
  
    // Validate email
    if (!email) {
      setEmailError('Please enter email');
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    } else {
      setEmailError('');
    }
  
    // Validate phonenumber
    if (!phonenumber) {
      setPhonenumberError('Please enter phone number');
      return false;
    } else {
      setPhonenumberError('');
    }
  
    // Validate query
    if (!query) {
      setQueryError('Please enter your message');
      return false;
    } else {
      setQueryError('');
    }
  
    // If all fields are validated successfully
    return true;
  };
  
  // Validation functions for each field
  const validateFullname = () => {
    if (!fullname) {
      setFullnameError('Please enter full name');
      return false;
    }
    setFullnameError('');
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError('Please enter email');
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  const validatePhonenumber = () => {
    if (!phonenumber) {
      setPhonenumberError('Please enter phone number');
      return false;
    }
    setPhonenumberError('');
    return true;
  };

  const validateQuery = () => {
    if (!query) {
      setQueryError('Please enter your message');
      return false;
    }
    setQueryError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation_flag = validateFormSequentially();
    if (!validation_flag ) {
      return;
    }

    try {
      const formData = { fullname, email, phonenumber, query, contact_us_submittion: '' };
      const response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);

      if (response.data && Array.isArray(response.data) && response.data[0]) {
        const successToast = toast.success(response.data[0]);
        setFullname('');
        setEmail('');
        setPhonenumber('');
        setQuery('');
        setTimeout(() => {
          window.location = "";
        }, 3000);
      } else {
        toast.error("Unexpected response from the server.");
        setLoaderState(false);
      }
    } catch (error) {
      toast.error("Error submitting form");
      setLoaderState(false);
    }
  };

  const fetchPageData = async () => {
    try {
      setLoaderState(true);
      const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
      document.title = "Broadway Originals | Contact Us";
      const responseData = response?.data || {};

      setNavlist(
        <Header
          navlist={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setMainNavlist(<MainNav navlist={JSON.stringify(responseData.navlist || {})} />);
      setPageFooter(
        <Footer
          nav_list={JSON.stringify(responseData.navlist || {})}
          socailmedia={JSON.stringify(responseData.socailmedia || {})}
        />
      );
      setPageMetaTags(responseData.pagemetatags || []);
      setLoaderState(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderState(false);
    }finally {
      setLoaderState(false);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: '0px !important',
          background: 'url(https://source.unsplash.com/random/1920x1080)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        <ToastContainer />
        {navlist}
        {mainnavlist}
        <MetaTags tags={pagemetatags} />
        {loaderState && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress />
          </Box>
        )}
        {!loaderState && (
          <Container maxWidth="lg" sx={{ padding: { xs: '20px', md: '0px' }, marginTop: '40px', color: 'black' }}>          
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card
                    size="lg"
                    variant="plain"
                    orientation={isMobile ? "vertical" : "horizontal"}
                    sx={{
                      textAlign: 'left',
                      maxWidth: '100%',
                      // to make the demo resizable
                      resize: 'horizontal',
                    }}
                  >  
                    <CardContent sx={{ gap: 1.5, minWidth: 100 }}>
                      <CardContent>
                      <Typography
                        variant="h2"
                        gutterBottom
                        sx={{
                          color: 'rgb(0, 123, 124)',
                          fontSize: '1.75rem',
                          fontWeight: 'bold',
                          display: 'inline-block',
                          transition: 'transform 0.3s ease',
                          transformStyle: 'preserve-3d',
                          textAlign: 'left',
                          '&:hover': {
                            transform: 'rotateY(10deg) rotateX(10deg)',
                          },
                        }}
                      >
                        Contact Us
                      </Typography>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Enter Full Name"
                                name="fullname"
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                                onBlur={validateFullname}
                                error={!!fullnameError}
                                helperText={fullnameError}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Enter Email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={validateEmail}
                                error={!!emailError}
                                helperText={emailError}
                              />
                            </Grid>
                          </Grid>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Enter Phone Number"
                            name="phonenumber"
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                            onBlur={validatePhonenumber}
                            error={!!phonenumberError}
                            helperText={phonenumberError}
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Enter Message"
                            multiline
                            rows={8}
                            name="query"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onBlur={validateQuery}
                            error={!!queryError}
                            helperText={queryError}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ backgroundColor: "#00A8AA", marginTop: "1rem" }}
                          >
                            Send
                          </Button>
                        </form>
                      </CardContent>
                      
                    </CardContent>
                    <CardOverflow
                      variant="solid"
                      color="primary"
                      sx={{
                        flex: '0 0 50%',
                        display: 'flex',
                        flexDirection: 'column',
                        px: 'var(--Card-padding)',
                        background: '#0086861c',
                      }}
                    >
                      <Box display="flex" mb={3}>
                        <Avatar sx={{background: '#00a8a9'}}>
                          <MailIcon />
                        </Avatar>
                        <Box ml={2}>
                          <Typography variant="h6" gutterBottom sx={{textAlign: 'left', color: 'black'}}>
                            Email
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            contact@broadwayoriginals.com
                          </Typography>
                        </Box> 
                      </Box> 
                      <Box display="flex" mb={3}>
                          <Avatar sx={{background: '#00a8a9'}}>
                            <PhoneIcon />
                          </Avatar>
                          <Box ml={2}>
                            <Typography variant="h6" gutterBottom sx={{textAlign: 'left', color: 'black'}}>
                              Phone
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              (+91) 1234567890
                            </Typography>
                          </Box>
                      </Box>
                      <Box display="flex" mb={3}>
                        <Avatar sx={{background: '#00a8a9'}}>
                          <LocationOnIcon />
                        </Avatar>
                        <Box ml={2}>
                          <Typography variant="h6" gutterBottom sx={{textAlign: 'left', color: 'black'}}>
                            Address
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{textAlign: 'left', color: 'black'}}>
                            Sector 2-1-23, Hyderabad, India - 500001
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ width: '100%',  margin: '0px', padding: '0px' }}>                       
                        <iframe 
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.3581876670382!2d78.48334857683966!3d17.394589860062357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99c56b6a0d5d%3A0xeeac5299de9bc88!2s23%2C%202%2C%20Street%201%2C%20Venkateshwara%20Colony%2C%20King%20Koti%2C%20Hyderguda%2C%20Hyderabad%2C%20Telangana%20500095!5e0!3m2!1sen!2sin!4v1713555873123!5m2!1sen!2sin"
                          width="100%"
                          height="325" // Adjust height as needed
                          style={{ border: 0 }}
                          loading="lazy"
                          title="Google Map Location"
                        />
                      </Box>
                    </CardOverflow> 
                  </Card>
                </Grid>
              </Grid> 
             
            </Box>
          </Container>
        )}
      </Container>
      {pageFooter}
    </>
  );
};

export default Contact;
