import React from 'react';
import Section from './HomePost';
import technology_img from '../images/Technology.png';
function Technology({ todaynews }) {
  const todaynews_list = JSON.parse(todaynews);

  return (
    <>
      {todaynews_list.length > 0 && (
        <Section
          title="Technology"
          image={technology_img}
          newsData={todaynews_list}
          sectionUrl="/technology"
        />
      )}
    </>
  );
}

export default Technology;
