import React from 'react';
import { Box, Grid, Container, Typography, IconButton, Card } from '@mui/material';
import Slider from 'react-slick';
import trendingIcon from '../images/trending-icon.png'; // Assuming this is the trending icon
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Material-UI icons for arrows
import CardPost from './CardPost';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Trending = ({ trandingdata }) => {
  const trending_list = JSON.parse(trandingdata);

  // Custom Next Arrow
  const NextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      <ArrowForwardIos />
    </IconButton>
  );

  // Custom Previous Arrow
  const PrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      <ArrowBackIos />
    </IconButton>
  );

  // Slider settings for react-slick
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
      <>
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <img src={trendingIcon} alt="Trending Icon" style={{ marginRight: '8px' }} />
              <Typography variant="h4" sx={{ color: '#00A8AA', fontWeight: 'bold' }}>
                Trending
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Slider {...sliderSettings}>
          {trending_list.map((item, index) => (
            <Box key={index} sx={{ p: 2 }}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '10px !important' }}>
                <CardPost post={item} />
              </Card>
            </Box>
          ))}
        </Slider>
      </>
  );
};

export default Trending;
