// src/pages/CreatePostPage.js
import React, { useState } from 'react';
import Editor from '../components/Editor';
import MarkdownRenderer from '../components/MarkdownRenderer';
import SocialShare from '../components/SocialShare';
import { Button } from '@mui/material';

const CreatePostPage = () => {
  const [content, setContent] = useState('');

  const handleSave = () => {
    // Logic to save the post can be added here
    alert('Post saved!');
  };

  return (
    <div>
      <h2>Create a Blog Post</h2>
      <Editor setContent={setContent} />
      <h2>Preview</h2>
      <MarkdownRenderer markdownText={content} />
      <SocialShare url={window.location.href} />
      <Button variant="contained" onClick={handleSave}>
        Save Post
      </Button>
    </div>
  );
};

export default CreatePostPage;
