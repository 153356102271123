import React, { useEffect } from 'react';

function MetaTags({ tags }) {
  useEffect(() => {
    // Remove all existing meta tags except 'viewport' and 'theme-color'
    const allMetaTags = document.querySelectorAll('meta');
    allMetaTags.forEach(tag => {
      const nameAttr = tag.getAttribute('name');
      if (nameAttr !== 'viewport' && nameAttr !== 'theme-color') {
        tag.parentNode.removeChild(tag);
      }
    });

    // Create new meta tags based on the provided data
    tags.forEach(tag => {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', tag.tag_name);
      newMetaTag.setAttribute('content', tag.tag_content);
      document.head.appendChild(newMetaTag);
    });

    // Clean up: Remove all added meta tags when component unmounts or tags change
    return () => {
      const addedMetaTags = document.querySelectorAll('meta[name="keywords"], meta[name="description"]');
      addedMetaTags.forEach(tag => tag.parentNode.removeChild(tag));
    };
  }, [tags]);  // Effect runs when 'tags' change

  return null;  // This component doesn't render anything
}

export default MetaTags;
