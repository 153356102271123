import * as React from 'react';
import log_image from '../images/white.png';
import {
  Container,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Typography,
  InputBase,
  MenuItem,
  Menu,
  Drawer,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faUserCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material';
import axios from 'axios';
import '../css/styles.css';
import DrawerMenu from './OffCanvas';
const options = [
  {
    name: <FontAwesomeIcon icon={faBars} />, 
    scroll: false,
    backdrop: true, 
  },
];
// Styled components for search input
const NavSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: '100px',
  marginRight: '100px',
  width: '100%',
}));

const NavSearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const Header = ({ navlist, socailmedia }) => {
  const navListJson = JSON.parse(navlist);
  const socialMediaJson = JSON.parse(socailmedia);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchQueryNav, setSearchQueryNav] = React.useState('');
  const [searcNavhResults, setSearchNavResults] = React.useState([]);
  const [searchNavOpen, setSearchNavOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // Loading state for search
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  React.useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { expiresAt } = JSON.parse(userData);
      if (expiresAt && new Date(expiresAt) > new Date()) {
        setIsLoggedIn(true);
      }else{
        setIsLoggedIn(false);
      }
    }else{
      setIsLoggedIn(false);
    }
  }, []);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    window.location.href = '/signin';
  };
  const handleNavSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQueryNav(query);

    if (query.length > 2) {
      setLoading(true);
      try {
        const response = await axios.post('https://broadwayoriginals.com/apis/index.php', { search: query });
        setSearchNavResults(response.data);
        setSearchNavOpen(true);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchNavResults([]);
        setSearchNavOpen(false);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchNavResults([]);
      setSearchNavOpen(false);
    }
  };

  const handleResultClick = (result) => {
    window.location = '/' + result.custom_url;
    setSearchNavOpen(false);
  };
  const handleOpenResultClick = (custom_pageurl) => {
    window.location = '/' + custom_pageurl;
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={closeMenu}
    >
      {isLoggedIn ? (
        <>
          <MenuItem onClick={() => handleOpenResultClick('dashboard')}>Dashboard</MenuItem>
          <MenuItem onClick={() => handleOpenResultClick('changepassword')}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={() => handleOpenResultClick('signin')}>Login</MenuItem>
          <MenuItem onClick={() => handleOpenResultClick('signup')}>Register</MenuItem>
        </>
      )}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={closeMenu}
    >
      {isLoggedIn ? (
        <>
          <MenuItem onClick={() => handleOpenResultClick('dashboard')}>Dashboard</MenuItem>
          <MenuItem onClick={() => handleOpenResultClick('changepassword')}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={closeMenu}>Login</MenuItem>
          <MenuItem onClick={closeMenu}>Register</MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: "linear-gradient(177deg, rgb(0, 168, 169) 0%, rgb(0, 123, 124) 100%)", boxShadow: "none" }}>
        <Container maxWidth="lg" sx={{padding: "0px !important"}}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>

            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{ flexGrow: 1, textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }}
            >
              <img
                src={log_image}
                alt="logo"
                style={{
                  height: '40px',
                  width: 'auto',
                  marginRight: '0px',
                }}
              />
            </Typography>

            {/* Search Section */}
            {!isMobile && (
              <NavSearch>
              <NavSearchIconWrapper>
                <SearchIcon />
              </NavSearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                name="navsearch"
                value={searchQueryNav}
                onInput={handleNavSearchChange}
              />
              {searchNavOpen && (
                <Box
                  className="search-scroll" // Add class for custom scrollbar styling
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    boxShadow: 1,
                    zIndex: 1,
                    maxHeight: '400px', // Optional: Add max height for better UX
                    overflowY: 'auto', // Allow vertical scrolling
                  }}
                >
                  {loading ? (
                    <MenuItem sx={{ color: "black" }}>Loading...</MenuItem>
                  ) : (
                    searcNavhResults.map((result) => (
                      <MenuItem
                        key={result.custom_url}
                        onClick={() => handleResultClick(result)}
                        sx={{ color: "black", display: 'flex', alignItems: 'center', padding: '10px' }}
                      >
                        {result.post_image && (
                          <Box
                            component="img"
                            src={"https://broadwayoriginals.com/" + result.post_image}
                            alt={result.post_title}
                            sx={{
                              width: '60px', // Image width
                              height: '60px', // Image height
                              borderRadius: '4px',
                              marginRight: '10px',
                            }}
                          />
                        )}
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              overflowWrap: 'break-word', // Allows long words to break
                              wordBreak: 'break-word', // Breaks words if necessary
                              whiteSpace: 'normal', // Allows the text to wrap
                            }}
                          >
                            {result.post_title}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'gray' }}>
                          By {result.author_name} | {result.category.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </Box>
              )}
            </NavSearch>
            )}

            {/* Social Media Icons Section */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', marginRight: "30px" }}>
              {socialMediaJson.map((mediadata) => (
                <IconButton
                  key={mediadata.name}
                  href={mediadata.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  style={{ padding: '0 5px' }}
                >
                  <FontAwesomeIcon
                    icon={
                      mediadata.name === 'facebook' ? faFacebookF :
                      mediadata.name === 'twitter' ? faTwitter :
                      mediadata.name === 'linkedin' ? faLinkedinIn :
                      mediadata.name === 'instagram' ? faInstagram :
                      faYoutube
                    }
                  />
                </IconButton>
              ))}
            </Box>

            {/* User Icon */}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={openMenu}
                sx={{
                  padding: isMobile ? '8px' : '12px',  // Smaller padding for mobile
                  fontSize: isMobile ? '30px' : '2rem',  // Adjust icon size for mobile
                }}
              >
                <FontAwesomeIcon icon={faUserCircle} />
              </IconButton>
              {renderMenu}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer Menu */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 350 }}
          role="presentation"
          onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside
          onKeyDown={toggleDrawer(false)} // This will still close the drawer with keyboard navigation
        >
          <DrawerMenu
            name="Menu"
            navcontent={navlist}
            socailmediainfo={socailmedia}
            options={options}
            onClose={toggleDrawer(false)}
          />
        </Box>
      </Drawer>

      {renderMobileMenu}
    </Box>
  );
};

export default Header;
