// src/components/MarkdownRenderer.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/material';
import CodeBlock from './CodeBlock'; // Import the CodeBlock component
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownRenderer = ({ markdownText }) => {
  const renderers = {
    code: ({ language, value }) => <CodeBlock language={language} code={value} />,
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: '20px auto' }}>
      <ReactMarkdown components={renderers}>
        {markdownText}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer;
