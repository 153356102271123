// src/pages/PostPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import BlogPost from '../components/BlogPost';

const PostPage = () => {
  const { id } = useParams();

  // For demonstration, we'll use static data. In a real application, fetch this data from an API or a state management solution.
  const post = {
    title: `Post ${id}`,
    content: `This is the content for post ${id}.`
  };

  return <BlogPost title={post.title} content={post.content} />;
};

export default PostPage;
