import React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// SolutionCard component to render individual solution details
const SolutionCard = ({ solution }) => {
  return (
    <Card sx={{ maxWidth: 300, boxShadow: 3, p: 2 }}>
      <Chip size="sm" variant="outlined" color="neutral" sx={{ mb: 1 }}>
        {solution.solution_name.toUpperCase()}
      </Chip>
      <Divider />
      <List size="sm" sx={{ mt: 1, background: 'none' }}>
        {solution.features.map((feature, index) => (
          <ListItem key={index} sx={{fontSize: '20px'}}>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {feature}
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

// SolutionList component to parse and render SolutionCard components
const SolutionList = ({ solutionData }) => {
  let pageContent = JSON.parse(solutionData);
  return (
    <>
        <Typography variant="h6" sx={{ color: "#00A8AA", mt: 5}}>
            Our Solutions
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, pt: 2}}>
        {pageContent!="" && pageContent.length > 0 ? (
            pageContent.map((solution, index) => (
            <SolutionCard key={index} solution={solution} />
            ))
        ) : (
            <Typography variant="body1" color="text.secondary">
            
            </Typography>
        )}
        </Box>
    </>
    
  );
};


export default SolutionList;