import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
function ForgotPassword({ open, handleClose }) {
  const [resendemailid, setResendEmailId] = useState('');
  const resendEmail = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {"forgot_resendemail": "", "email": resendemailid});
        if (response && response.data.status == "1") {
            toast.success(response.data.msg);
        } else {
            toast.error(response.data.msg);
        }
    } catch (error) {
        console.log(error);
        toast.error("Error submitting form");
    }
  };
  return (
    <>
    <ToastContainer />
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
        sx: { backgroundImage: 'none' },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a link to
          reset your password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          value={resendemailid}
          onChange={(e) => setResendEmailId(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button> 
        <Button variant="contained" onClick={resendEmail} sx={{background: '#009697'}}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;